<template>
	<div class="User">
		
		<div class="Top">
			<div class="Left">
				<el-button type="primary" @click="$Jump('/do/help/list')">返回</el-button>
				<span style="margin-left: 10px;">				
					<el-button type="danger" @click="$Jump('/do/help/category_info/add')">添加分类</el-button>
				</span>
			</div>
			<div class="Right">
				<span>
				
				</span>

			</div>
		</div>
		
		<div class="List">
			
			<div class="Title">
				<div class="C1">分类名</div>
				<div class="C3">状态</div>
				<div class="C4">创建时间</div>
				<div class="C5">分类ID</div>
				<div class="C6">操作</div>
			</div>
			
			<div class="One" v-for="item in CategoryList" :key="item.Id">
				<li>
					<div class="C1">{{item.Name}}</div>
					<div class="C3">{{item.StatusName}}</div>
					<div class="C4">{{item.CreatedAt}}</div>
					<div class="C5">{{item.Id}}</div>
					<div class="C6">
						<el-button size="mini" @click="$Jump('/do/help/category_info/'+item.Id)">管理</el-button>
					</div>
				</li>
			</div>
			
		</div>
		
		<div class="Page">
			<el-pagination
			  background
			  layout="prev, pager, next"
			  :total="Total" :page-size="PageSize" :current-page="Page" @current-change="handleCurrentChange">
			</el-pagination>
		</div>

	</div>
</template>

<script>
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,Pagination} from 'element-ui'
	export default {
	  name: 'HelpCategoryList',
	  props: {
	  },
	  data() {
	      return {
			  CategoryList:[],
			  Page:1,
			  PageSize:15,
			  Total:0
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-pagination':Pagination
	  },
	  created() {
	  	this.GetList(1)
	  },
	  methods:{
		  handleCurrentChange(val){
			 this.GetList(val)
		  },
		  GetList(_page){
		  		let data = {
		  			Service:'Help',
		  			Class: 'HelpCategory',
		  			Action: 'List',
		  			Page:_page,
		  			PageSize:this.PageSize,
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){this.$message(res.Msg);return;}
					this.CategoryList = res.Data.HelpCategoryList
					this.Page = res.Data.CurrentPage
					this.Total = res.Data.Total
		  			
		  		})
		  		.catch(function (response) {
					this.$message('网络请求错误')
		  		})
		  }
	  }
	}
</script>

<style scoped>
.User{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.User .Top{
	display: flex;
	align-items: center;
	border-bottom: 1px solid rgba(0,0,0,0.05);
	padding-bottom: 20px;
}
.User .Top .Left{
	
}
.User .Top .Right{
	flex: 1;
	display: flex;
	color: #999999;
	justify-content: flex-end;
}
.User .Top .Right span{
	margin-left: 10px;
}
.User .List{
}
.C1,.C2,.C3,.C4,.C5{
	width: 150px;
}
.C5{
	width: 300px;
	color: rgba(0,0,0,0.4);
}
.C1{
	padding-left: 10px;
}
.C6{
	flex: 1;
	text-align: right;
	padding-right: 10px;
}
.User .List .Title{
	display: flex;
	margin-bottom: 20px;
	color: rgba(0,0,0,0.3);
	padding-bottom: 10px;
	padding-top: 10px;
	border-bottom: 1px solid rgba(0,0,0,0.04);
	font-size: 0.9rem;
}
.User .List .One{
	border-bottom: 1px solid rgba(0,0,0,0.04);
	padding: 10px 0px;
}
.User .List .One:hover{
	background-color: rgba(228,0,0,0.1);
}
.User .List .One li{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
</style>
